import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Projects from "../components/Projects"
import SEO from "../components/SEO"
// ...GatsbyImageSharpFluid

const ProjectsPage = ({
  data: {
    allContentfulProject: { nodes: projects },
  },
}) => {
  return (
    <Layout isHome={false}>
      <SEO title="Projects" description="Projects done by Sadat Jubayer" />
      <Projects
        subheading="Showcase of my top projects"
        projects={projects}
        title="All Projects"
      />
    </Layout>
  )
}

export default ProjectsPage

export const query = graphql`
  {
    allContentfulProject(sort: { fields: serial }) {
      nodes {
        git
        id
        link
        techs
        title
        cover {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        description {
          description
        }
      }
    }
  }
`
